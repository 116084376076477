<template>
  <div class="flex flex-col py-4 max-w-2xl mx-auto relative">
    <h1
      class="text-2xl font-semibold text-gray-900 mb-4 sticky top-0 py-3 bg-gray-100"
    >
      <router-link to="/messaging/conversations"> Conversation </router-link>
      <span class="text-sm ml-1">
        /
        {{
          conversation.customer_label == conversation.customer_number
            ? conversation.customer_number
            : `${conversation.customer_label} (${conversation.customer_number})`
        }}
      </span>
      <a
        class="text-red-700 text-sm ml-2 cursor-pointer"
        @click="toggleModal()"
      >
        Set Name
      </a>
    </h1>

    <div ref="messages" class="overflow-y-auto">
      <div
        v-for="message in messages"
        :key="message.timestamp"
        class="flex flex-col mt-10"
      >
        <div
          :class="message.to_from"
          class="message-box w-2/3 rounded-md shadow p-4 mb-2 border-l-8 border-gray-700"
        >
          <div class="flex flex-col justify-between gap-4 ml-6">
            <div class="label font-bold text-black text-lg">
              <img
                v-if="message && message.image_attachment"
                :src="message.image_attachment"
              />
              {{ message.message }}
            </div>
            <div class="timestamp text-sm">
              {{ message.date }}
            </div>
          </div>
        </div>
        <div :class="message.to_from" class="mb-6">
          {{
            message.to_from == "customer"
              ? conversation.customer_label == conversation.customer_number
                ? conversation.customer_number
                : `${conversation.customer_label} (${conversation.customer_number})`
              : message.sender_name
          }}
        </div>
      </div>
    </div>

    <form @submit.prevent="sendMessage">
      <div role="alert" class="my-3" v-if="errorMsg != ''">
        <div
          class="border border-red-400 rounded text-center bg-red-100 text-sm px-4 py-3 text-red-700"
        >
          <p>{{ errorMsg }}.</p>
        </div>
      </div>

      <div class="flex justify-evenly">
        <textarea class="w-full max-h-60 p-2" v-model="message.text">
        </textarea>
        <button type="submit" class="bg-red-700 text-white px-7 h-16 font-bold">
          Send
        </button>
      </div>

      <input
        class="mt-3"
        type="file"
        accept="image/*"
        @change="previewImage"
        ref="image"
      />
      <div
        v-if="message.imageData || message.img"
        class="mt-2 bg-gray-600 w-max relative"
      >
        <button
          class="absolute -top-2 -right-3 bg-white rounded-full"
          @click="removeImage()"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            width="30px"
            data-icon="times-circle"
            class="svg-inline--fa fa-times-circle fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
            ></path>
          </svg>
        </button>
        <img height="268" width="356" :src="message.imgObject" />
      </div>
    </form>

    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="showPopup"
    ></div>

    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Enter name for <b>{{ conversation.customer_number }}</b>
          </div>
          <form @submit.prevent="saveName" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="flex flex-col mb-6 w-full mt-2">
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  placeholder="Enter Name.."
                  v-model="customer_name"
                  name="customer_name"
                  autocomplete="off"
                  required
                  id="customer_name"
                />
              </div>
            </div>
            <div role="alert" class="my-3" v-if="errorMsg">
              <div
                class="border border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700"
              >
                <p>{{ errorMsg }}.</p>
              </div>
            </div>

            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full bg-red-700 text-white py-2 px-4 rounded-full md:mr-3 text-center font-bold"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Saving Name..." : "Save Name" }}
              </button>
              <button
                class="flex-no-shrink border-2 border-red-700 text-red-700 font-bold py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div id="bottom"></div>
</template>

<script>
const firebase = require("../firebaseConfig");
const { DateTime } = require("luxon");
import axios from "axios";

export default {
  name: "SingleConversation",
  data: () => ({
    messages: [],
    customer_name: "",
    errorMsg: "",
    message: {
      text: "",
      imageData: "",
      imgObject: "",
      imgName: "",
      imgDownloadableUrl: "",
    },
    conversation: {},
    user: {},
    business: {},
    location: {},
    showPopup: false,
  }),
  async mounted() {
    this.user = await this.getUserInfo();
    this.location = await this.getLocationInfo();
    this.business = await this.getBusinessInfo();
    this.conversation = await this.getSingleConversation();
    this.getMessagesThread();
    this.readMessage();
  },
  methods: {
    async saveName() {
      await firebase.db
        .collection("businesses")
        .doc(this.business.id)
        .collection("locations")
        .doc(this.location.id)
        .collection("conversations")
        .doc(this.conversation.id)
        .update({
          customer_label: this.customer_name,
        });

      const savenameSnapshot = await firebase.db
        // .collectionGroup("customers")
        .collection(`businesses/${this.business.id}/customers`)
        .where("customer_number", "==", this.conversation.customer_number)
        .get();

      const customerPath = savenameSnapshot.docs[0].ref.path;

      await firebase.db.doc(customerPath).update({
        customer_label: this.customer_name,
      });

      this.conversation = await this.getSingleConversation();
      this.showPopup = !this.showPopup;
    },
    clearForm() {
      this.message.text = "";
      this.message.imageData = "";
      this.message.imgObject = "";
      this.message.imgName = "";
      this.message.imgDownloadableUrl = "";
      this.$refs.image.value = "";
    },
    toggleModal() {
      this.showPopup = !this.showPopup;
    },
    previewImage(event) {
      this.message.imageData = event.target.files[0];
      this.message.imgObject = URL.createObjectURL(this.message.imageData);
      this.message.imgName = `${this.message.imageData.lastModified}${this.message.imageData.name}`;
    },
    removeImage() {
      this.$refs.image.value = null;
      this.message.imageData = null;
      this.message.imgObject = null;
    },
    async sendMessage() {
      const message = { ...this.message };
      this.errorMsg = "";
      if (message.text.trim() == 0 && message.imgObject == "") {
        this.errorMsg =
          "Please enter a message or select an image, content must not be empty ";
        return false;
      }
      this.clearForm();

      const authToken = await firebase.auth.currentUser.getIdToken();

      if (message.imageData) {
        const storageRef = firebase.storage.ref(
          `${this.business.id}/send-text/${message.imgName}`
        );

        await storageRef.put(message.imageData);

        console.info("mssg objct is ", message.imgObject);

        message.imgDownloadableUrl = await firebase.storage
          .ref(this.business.id)
          .child("send-text")
          .child(message.imgName)
          .getDownloadURL();
      }

      // record message sent in business
      await firebase.db
        .collection("businesses")
        .doc(this.business.id)
        .update({
          messages_sent: firebase.firestore.FieldValue.increment(1),
        });

      // record message sent in the location
      await firebase.db
        .collection("businesses")
        .doc(this.business.id)
        .collection("locations")
        .doc(this.location.id)
        .update({
          location_messages_sent: firebase.firestore.FieldValue.increment(1),
        });

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/sendTwilio`,
        headers: {
          Authorization: authToken,
        },
        data: {
          to: this.conversation.customer_number,
          from: this.location.twilio_number,
          content: message.text,
          ...(message.imgDownloadableUrl && {
            media: message.imgDownloadableUrl,
          }),
          is_conversation: true,
          display_name: this.user.display_name,
          business_id: this.business.id,
          location_id: this.location.id,
          is_mass_text: false,
          is_review_text: false,
          is_send_text: true,
        },
      });

      this.scrollToBottom();
    },
    async readMessage() {
      let messages = [];
      const messageSnapshot = await firebase.db
        // .collectionGroup("messages")
        .collection(
          `businesses/${this.business.id}/locations/${this.location.id}/messages`
        )
        .where("customer_number", "==", this.conversation.customer_number)
        .get();

      messageSnapshot.forEach((doc) => {
        if (
          doc.data() &&
          doc.data().unread_by &&
          doc.data().unread_by.length != 0
        ) {
          messages.push({
            id: doc.id,
            path: doc.ref.parent.path,
            ...doc.data(),
          });
        }
      });

      messages.forEach(async (message) => {
        const unread_users = message.unread_by;
        if (!unread_users.includes(this.user.id)) {
          return false;
        }
        const index = unread_users.indexOf(this.user.id);
        unread_users.splice(index, 1);

        await firebase.db.doc(`${message.path}/${message.id}`).update({
          unread_by: unread_users,
        });
      });
    },
    transformDate(seconds) {
      let date = new Date(0);
      date.setUTCSeconds(seconds);
      const ISOdate = date.toISOString();

      return DateTime.fromISO(ISOdate)
        .setZone("America/Detroit")
        .toFormat("LLL. dd, yyyy hh:mm a");
    },
    getSingleConversation() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("businesses")
            .doc(this.user.business_id)
            .collection("locations")
            .doc(this.user.location_id)
            .collection("conversations")
            .doc(this.$route.params.conversation_id)
            .onSnapshot((doc) => {
              resolve({
                id: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    scrollToBottom() {
      setTimeout(() => {
        const lastMessage = document.getElementById("bottom");
        lastMessage.scrollIntoView({
          block: "end",
        });
      }, 0);
    },
    getMessagesThread() {
      firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .collection("locations")
        .doc(this.user.location_id)
        .collection("messages")
        .where("customer_number", "==", this.conversation.customer_number)
        .orderBy("timestamp", "asc")
        .onSnapshot((docs) => {
          this.messages = [];
          docs.forEach((doc) => {
            this.messages.push({
              id: doc.id,
              ...doc.data(),
              date: this.transformDate(doc.data().timestamp.seconds),
            });
          });
          this.scrollToBottom();
        });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve({ id: user.id, ...user.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocationInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("businesses")
          .doc(this.user.business_id)
          .collection("locations")
          .doc(this.user.location_id)
          .get()
          .then((location) => {
            resolve({ id: location.id, ...location.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("businesses")
          .doc(this.user.business_id)
          .get()
          .then((business) => {
            resolve({ id: business.id, ...business.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>

<style scoped>
.staff {
  @apply self-end;
}
.staff.message-box {
  @apply bg-blue-100;
}
.customer.message-box {
  @apply bg-gray-200;
}
</style>
